<template>
  <div class="fill-heigt pt-0" fluid>
    <v-row>
      <v-col cols="12" md="8">
        <div class="text-left black--text" style="margin-top:3%">
         {{$t('activities')}}
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-right">
          <v-text-field
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-3"></v-divider>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="headers"
          :items="vacancies"
          class="elevation-1"
          dense
        >
          <template v-slot:no-data>
            {{$t('nodata')}}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Activity',
  computed: {
    headers () {
    //  let firstHeader;
    //   if (this.currentUser.cargo === "Parceiro") {
    //     firstHeader = { text: "Cliente", value: "entity" }
    //   } else if (this.currentUser.type === "entity") {
    //     firstHeader = { text: "Parceiro", value: "partner" }
    //   } else {
        return [
         { text: this.$t('Company'), value: "entity" } ,
         { text: this.$t('partner'), value: "partner" },
        { text: this.$t('testtitle'), value: "title" },
        { text: this.$t('vacancy'), value: "vacancy" },
        { text: this.$t('candidates'), align: "center",  value: "candidates" },
        { text: this.$t('price'), value: "price" },
        { text: this.$t('totalPrice'), value: "totalPrice" },
        { text: this.$t('date'), align: "center", value: "date" },
        // { text: "Acções", value: "actions", sortable: false }
      ];
      // }
      // return [
      //   firstHeader ,
      //   { text: "Titulo do teste", value: "title" },
      //   { text: "Vaga", value: "vacancy" },
      //   { text: "Candidatos", align: "center",  value: "candidates" },
      //   { text: "Preço unitário", value: "price" },
      //   { text: "Preço Total", value: "totalPrice" },
      //   { text: "Data", align: "center", value: "date" },
      //   // { text: "Acções", value: "actions", sortable: false }
      // ];
    },
  }
}
</script>